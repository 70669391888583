<template>
    <div class="landing list">
        <header class="common">
            <h1 @click="moveHome()" >Story Work</h1>
            <h2>
                <strong>{{ userName }}</strong
                >님의 편집보관함
            </h2>

            <nav>
                <button type="button" class="edit" @click="goMainSite">
                    메인사이트로
                </button>
                <button type="button" class="edit" @click="editStory">
                    선택파일 편집
                </button>
                <button type="button" class="delete" @click="deleteStory">
                    선택파일 삭제
                </button>
                <button
                    type="button"
                    class="event"
                    @click="exhibitStory"
                    v-if="contest.id > -1"
                >
                    선택파일 스토리보드 공모전 응모하기
                </button>
            </nav>
        </header>
        <main>
            <ul class="loads">
                <li>
                    <button type="button" class="new" @click="newStory">
                        <i></i>새로 만들기
                    </button>
                </li>
                <li :key="story.id" v-for="story in storys">
                    <label class="radio">
                        <input
                            type="radio"
                            name="load"
                            :value="story.id"
                            v-model="selectStory"
                        />
                        <i></i>
                        <div>
                            <img :src="getPreviewUrl(story)" />
                            <h3>{{ story.name }}</h3>
                        </div>
                    </label>
                </li>
            </ul>
        </main>
        <footer class="common">
            <ul class="info">
                <li>서울 중구 세종대로 14, 5층 523호(남대문로5가, 그랜드센트럴) (주)보물상자 (우)04527</li>
                <li>대표이사 : 송근우</li>
                <li>사업자등록번호 : 544-88-01808</li>
                <li>통신판매신고 : 2021-전주덕진-0726</li>
                <li>storyworkhelp@gmail.com</li>
            </ul>

            <p class="copyright">
                COPYRIGHT &copy; <span class="ci">(주)보물상자</span>.
                <span class="ww">ALL RIGHTS RESERVED.</span>
            </p>
        </footer>
    </div>
</template>

<style scoped>
@import "/assets/landing.css";
</style>

<script>
import AppOptions from "../config/AppOptions.vue";

import axios from "axios";
import { API_URL } from "../config/config";

export default {
    props: ["id"],
    name: "Storage",
    data() {
        return {
            selectStory: -1,
            storys: [],
            userName: "",
            contests: [],
            contest: { id: -1 }
        };
    },

    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;

        next();
    },
    created() {
        const there = this;
        axios
            .get(API_URL + "story/")
            .then(res => {
                there.storys = res.data;
            })
            .catch(e => {
                console.error("Get Storys Error with : ", e);
            });

        axios
            .get("contests", {
                params: {
                    filter: "date"
                }
            })
            .then(res => {
                there.contests = res.data;
                const activeContests = there.contests.filter(
                    contest => contest.active
                );
                if (activeContests.length > 0)
                    there.contest = activeContests[0];
            })
            .catch(e => {
                console.error("Get Contests Error with : ", e);
            });

        axios
            .get("auth/profile/")
            .then(res => {
                there.userName = res.data.userName;
            })
            .catch(e => {
                console.error("Get story Error with : ", e);
            });
    },
    methods: {
        getPreviewUrl(story) {
            return API_URL + "file/" + story.previews[0];
        },
        deleteStory() {
            if (this.selectStory < 0) return;

            if (!window.confirm("복구할수 없습니다. 삭제하시겠습니까?")) return;

            const there = this;

            axios
                .delete(`story/${this.selectStory}`)
                .then(function(res) {
                    console.log("reuslt : ", res.data);
                    there.storys.splice(
                        there.storys.findIndex(
                            item => item.id == there.selectStory
                        ),
                        1
                    );

                    there.makeToast("Success");
                })
                .catch(function(err) {
                    console.log(" error : ", err);
                    there.makeToast("Fail");
                });
        },
        editStory() {
            if (this.selectStory < 0) {
                alert("파일을 선택하세요")
                return;
           }
            this.$router.push("/editor/" + this.selectStory);
        },
        goMainSite() {
            location.href = "https://storywork.co.kr";
            //this.$router.push("https://storywork.co.kr");
        },
        newStory() {
            this.$router.push("/editor/");
        },
        exhibitStory() {
            if (this.selectStory < 0) return;

            const there = this;

            axios
                .post("exhibits", {
                    story: this.selectStory,
                    contestId: this.contest.id
                })
                .then(
                    () => {
                        there.makeToast("정상 처리되었습니다.");
                    },
                    e => {
                        there.makeToast(e.response.data.message[0]);
                    }
                )
                .catch(e => {
                    console.log(e);
                });
        },
        makeToast(msg) {
            this.$notify({
                group: "notifyApp",
                title: "알림",
                text: msg
            });
        },
        moveHome() {
            if (
                !window.confirm(
                    "메인으로 이동하시겠습니까?"
                )
            )
                return;
            this.$router.push("/");
        }
    }
};
</script>
