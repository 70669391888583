<template>
    <div class="index landing">
        <div class="wrap">
            <header class="common">
                <h1>Story Work</h1>
                <nav class="member">
                    <router-link to="/login" v-show="isLoggedIn() === false"
                        >로그인</router-link
                    >
                    <a href="" @click="logout" v-show="isLoggedIn()"
                        >로그아웃</a
                    >
                    <router-link to="/register" v-show="isLoggedIn() === false" >회원가입</router-link>
                    <router-link to="/storage" v-show="isLoggedIn()" >보관함</router-link>
                    <router-link to="/gallery" v-show="isLoggedIn()" >공유갤러리</router-link>
                    <a href="https://storywork.co.kr" v-show="isLoggedIn()" >메인사이트로</a>
                </nav>
            </header>
            <main>
                <section class="intro">
                    <h2>
                        <strong>스토리워크</strong>는<br />
                        <strong>이야기편집기</strong>입니다.
                    </h2>
                    <p>
                        우리들의 이야기를 효과적으로 전달하는 방법!<br />
                        스토리워크로 누구나 쉽게 스토리보드를 만들 수 있습니다.
                    </p>
                    <router-link to="/editor" class="start"
                        >스토리워크 사용하기</router-link
                    >
                </section>
                <dl class="slogan">
                    <dt>StoryWork <span>=</span> StoryMaker</dt>
                    <dd>
                        우리들의 다양한 이야기들로 풍성해지는 세상을 꿈꿉니다.
                    </dd>
                </dl>
                <section class="editor">
                    <h2>
                        <strong>스토리워크</strong>에서<br />
                        <strong>상상은 현실</strong>이 됩니다.
                    </h2>
                    <p>
                        우리가 무엇이든 상상할 수 있듯<br />
                        스토리워크는 무엇이듯 가능합니다.
                    </p>
                    <router-link to="/editor" class="start"
                        >스토리워크 사용하기</router-link
                    >
                </section>
                <section class="cartoon">
                    <p>
                        스토리워크는 스토리보드(콘티)를 누구나 쉽게 만들 수 있는
                        온라인 편집기 입니다.<br />
                        좌측 메뉴에서 ‘포즈’를 선택하면 저의 다양한 포즈들을
                        검색 할 수 있습니다.<br />
                        선택한 포즈를 드래그&amp;드롭하면 편집 화면에 포즈가
                        추가 됩니다.<br />
                        8개의 포인트를 사용하여 더미맨을 원하는 크기로 키우고
                        줄이는 것도 할 수 있어요.<br />
                        여러가지 모양의 말풍선을 선택하여 화면에 배치하고
                        사이즈를 조절할 수 있습니다.<br />
                        글상자를 드래그하여 추가하고 원하는 대사를 입력
                        합니다.<br />
                        참 쉽죠?
                    </p>
                </section>
                <section class="contest" v-show="false">
                    <h2>제1회 <strong>스토리보드 공모전</strong></h2>
                    <article>
                        <dl>
                            <dt>공모주제</dt>
                            <dd>
                                10컷 이상 100컷 이내의 재미있는 이야기의
                                스토리보드(콘티)<br />
                                개그, 드라마, 동화, 웹툰 등 장르 무관
                            </dd>
                        </dl>
                        <dl>
                            <dt>공모방법</dt>
                            <dd>
                                http://www.storywork.co.kr에 접속 하여<br />
                                스토리워크로 콘티 제작 후 공모전 응모 버튼 클릭!
                            </dd>
                        </dl>
                        <dl>
                            <dt>공모대상</dt>
                            <dd>제한 없음(한 아이디 당 1개)</dd>
                        </dl>
                        <dl>
                            <dt>시상내역</dt>
                            <dd>
                                대상(1명) 상장 및 상금 100만원<br />
                                최우수상(1명) 상장 및 상금 50만원<br />
                                우수상(3명) 상장 및 10만원 상당 타블렛<br />
                                장려상(10명) 상장 및 2만원 상당 캐릭터 상품
                            </dd>
                        </dl>
                        <dl>
                            <dt>공모일정</dt>
                            <dd>
                                접수: 2021.10.10~2021.11.10<br />
                                발표: 2021.11.20, 스토리워크와 아이러브캐릭터
                                12월호 게재 및 개별 통보<br />
                                시상: 2021.11.27, 장소와 시간 추후 고지
                            </dd>
                        </dl>
                    </article>
                    <router-link to="/editor" class="start"
                        >스토리워크 사용하기</router-link
                    >
                </section>
            </main>
            <footer class="common" style="position:relative">
                <ul class="info">
                    <li>
                        서울 중구 세종대로 14, 5층 523호(남대문로5가, 그랜드센트럴) (주)보물상자 (우)04527
                    </li>
                    <li>대표이사 : 송근우</li>
                    <li>사업자등록번호 : 544-88-01808</li>
                    <li>통신판매신고 : 2021-전주덕진-0726</li>
                    <li>storyworkhelp@gmail.com</li>
                </ul>
                <p class="copyright">
                    COPYRIGHT &copy; <span class="ci">(주)보물상자</span>.
                    <span class="ww">ALL RIGHTS RESERVED.</span>
                </p>
            </footer>
        </div>
    </div>
</template>

<style scoped>
@import "/assets/landing.css";
</style>

<script>
import AppOptions from "../config/AppOptions.vue";

//import axios from "axios";
//import { API_URL } from "../config/config";

export default {
    name: "Home",
    data() {
        return {};
    },

    mounted() {
        AppOptions.appWithoutSidebar = true;
        AppOptions.appWithoutHeader = true;
        AppOptions.appContentFullWidth = true;
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appWithoutSidebar = false;
        AppOptions.appWithoutHeader = false;
        AppOptions.appContentFullWidth = false;

        next();
    },
    created() {},
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        }
    }
};
</script>
